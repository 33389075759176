#Frame {

    position: relative;


    main {
        min-height         : ceil(1080 - 399px);
        // background-color: #F9FAFC;
    }

    footer {
        width           : 100%;
        min-height      : 208px;
        background-color: #222222;
        color           : #999999;
        font-size       : 14px;
        padding-bottom  : 70px;

        .footer_container {
            cursor: default;

            .footer_firend_link {
                margin-bottom: 20px;

                >section:first-child {
                    padding      : 29px 0 17px;
                    border-bottom: 1px solid #999999;
                    margin-bottom: 32px;
                }

                >ul {
                    li {
                        margin-right: 31px;
                        cursor      : pointer;
                    }
                }
            }

            .footer_copy_container {
                font-size: 12px;

                img {
                    width       : 18px;
                    height      : 20px;
                    margin-right: 11px;
                }

                span {
                    cursor: pointer;
                }
            }
        }

    }

}
// Flex 常用Css属性

.flex {
    display: flex;
}

.flex-shrink {
    flex-shrink: 0;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-direction-row {
    flex-direction: row;
}

.flex-direction-column{
    flex-direction: column;
}

.flex-align-items-center {
    align-items: center;
}

.flex-align-items-start {
    align-items: flex-start;
}

.flex-align-items-end {
    align-items: flex-end;
}

.flex-align-self-start {
    align-self: flex-start;
}

.flex-align-self-end {
    align-self: flex-end;
}

.flex-align-self-center {
    align-self: center;
}

.flex-justify-content-center {
    justify-content: center;
}

.flex-justify-content-start {
    justify-content: flex-start;
}

.flex-justify-content-end {
    justify-content: flex-end;
}

.flex-justify-content-space-between {
    justify-content: space-between;
}

.flex-justify-content-space-around {
    justify-content: space-around;
}


// tools 工具Css属性

// 单行溢出省略号
.odd-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// 多行溢出省略号
.more-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    // display: -moz-box;
    // display: -moz-inline-box;
    // display: -webkit-inline-box;
    // -moz-box-orient: vertical;
    // -webkit-line-clamp: 2;
    // line-clamp: 2;
}
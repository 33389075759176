#Content {

    .content_filter_container {
        background-color: #F9FAFC;
        padding         : 30px 0;

        .filter_container {
            color: #222222;

            >section:not(:last-child) {
                margin-bottom: 15px;
            }

            .filter_date {
                width: 100%;

                >section {
                    width        : 100%;
                    cursor       : default;
                    // > section:first-child {
                    //     cursor: pointer;
                    // }
                }
            }
        }

        .filter_base {
            span {
                margin-right: 18px;
            }

            ul {
                margin-bottom: 0;

                user-select: none;

                li {
                    margin-right: 33px;
                    cursor      : pointer;
                }

                li:hover {
                    color: #154CCC;
                }

                .active {
                    color: #154CCC;
                }
            }

            .filter_three {
                padding-left: 75px;

                li {
                    padding      : 0 13px;
                    border-radius: 13px;
                    margin-bottom: 5px;
                    margin-right : 5px;
                    color        : #555555;
                }

                .active {
                    color: #fff;
                    background-image: linear-gradient(to right,
                            #2559D2,
                            #3797E1);
                }
            }
        }
    }

    // 三级分类
    .works_filter_child {
        width                  : 100%;
        // min-height          : 60px;
        background-color       : #F0F2F7;
        transition             : all .2s ease 0s;
        overflow               : hidden;
        // padding-top: 10px;

        .w {
            height: 100%;

            >section {
                margin-left: 22px;

                .works_filter_child_item,
                .works_filter_child_all {
                    margin-right : 23px;
                    cursor       : pointer;
                    position     : relative;
                    padding      : 0 13px;
                    border-radius: 13px;
                    transition   : all .2s ease 0s;
                    flex-shrink  : 0;
                    margin-bottom: 10px;
                }

                .active {
                    color: #fff;
                    background-image: linear-gradient(to right,
                            #2559D2,
                            #3797E1);
                }
            }
        }

    }

    .works_container {
        padding         : 30px 0;
        background-color: #fff;

        .works_item_container {

            .works_list_container {
                margin-bottom: 38px;
                margin-right : 16px;
            }

        }

        .works_list_container:nth-child(4n) {
            margin-right: 0;
        }
    }
}
* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

img,input,textarea,button,video{
    outline: none;
    vertical-align: middle;
}

html,body {
    height: 100%;
    background-color: #F9FAFC;
}

.w {
    width: 1200px;
    margin: 0 auto;
}

#AUpload {

    margin-bottom: 40px;

    .aupload_container {
        padding-bottom: 20px;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.05);

        // Tab切换
        .upload_tab_container {
            user-select: none;
            margin-bottom: 66px;

            .upload_tab_item {
                flex: 1;
                line-height: 80px;
                text-align: center;
                color: #666666;
                cursor: pointer;
                font-size: 18px;
                background-color: #ECEFF4;
                transition: all .2s ease 0s;

                &.active {
                    background-color: #fff;
                    color: #222;
                    font-size: 22px;
                    font-weight: bold;
                }
            }
        }


        // 上传
    }
}

.continueTips {
    margin-left: 410px;
    margin-top: -12px;
    color: black;
    font-size: 13px;
    display: block;
    // color: red;
}